import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-container',[_c('page-header',{attrs:{"title":"Literature"}}),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.pdfList),function(pdf){return _c(VCard,{key:pdf.pdf,staticClass:"elevation-12 mx-auto my-4",attrs:{"width":500}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('div',{staticClass:"text-overline mb-4",staticStyle:{"line-height":"1rem"}},[_vm._v(" "+_vm._s(pdf.outline)+" ")]),_c(VListItemTitle,{staticClass:"text-h5 mb-1",staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(pdf.title)+" ")]),_c(VListItemSubtitle,{staticStyle:{"white-space":"normal"}},[_vm._v(_vm._s(pdf.publisher))]),_c(VBtn,{attrs:{"outlined":"","rounded":"","text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openPDFDialog(pdf.title, pdf.pdf)}}},[_vm._v(" Read Now ")])],1),_c(VListItemAvatar,{attrs:{"tile":"","size":"200"}},[_c(VImg,{attrs:{"src":require(`@/assets/img/literature/${pdf.preview}`)}})],1)],1)],1)}),1),_c(VDialog,{attrs:{"persistent":"","overlay-color":"secondary darken-4","overlay-opacity":".4","transition":"dialog-bottom-transition","width":_vm.$vuetify.breakpoint.mdAndUp ? '70vw' : '90vw'},model:{value:(_vm.pdfDialog),callback:function ($$v) {_vm.pdfDialog=$$v},expression:"pdfDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":""}},[_c('span',[_vm._v(_vm._s(_vm.selectedTitle))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":"","dark":""},on:{"click":_vm.shareLink}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-share-variant")])],1),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){$event.stopPropagation();_vm.pdfDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('iframe',{ref:"embedPDF",staticClass:"mx-auto",staticStyle:{"border":"none"},attrs:{"height":500,"width":'100%'}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }